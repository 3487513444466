<template>
  <div>
    <div class="row my-3">
      <div class="col-sm-4">
        <button class="btn btn-warning" @click="obtener_solicitudes">Recargar</button>
      </div>
      <h1 class="col-sm-8 text-right">Solicitudes</h1>
    </div>

    <div class="row">
      <div v-for="solicitud in solicitudes" class="col-sm-4">
        <div class="card bpb-card">
          <div class="card-body">
            <div class="card-title">Solicitud {{ solicitud.solicitud_id }}</div>
            <div class="card-text">
              <ul class="detalle">
                <li v-if="$auth.can('fyc','usuario_operador')"><span class="title">Alianza:</span> {{ solicitud.alianza.nombre }}</li>
                <li><span class="title">Estatus:</span> {{ solicitud.estatus }}</li>
                <li><span class="title">Creación:</span> {{ $moment(solicitud.created_at).format('ddd DD MMM YYYY - HH:mm') }}</li>
              </ul>
            </div>
            <div class="card-text text-right"><router-link :to="{name: 'fyc_solicitudes_visualizador',params:{id: solicitud.id}}" class="btn btn-secondary">Revisar</router-link></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/apps/fyc/api/solicitudes';

export default {
  components: {
    
  },
  data() {
    return {
      solicitudes: []
    }
  },
  mounted() {
    this.obtener_solicitudes();
  },
  methods: {
    async obtener_solicitudes() {
      try {
        this.solicitudes = (await api.obtener_solicitudes()).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>

<style lang="scss">
  .bpb-card {
    .card-text {
      ul {
        list-style: none;
        margin-right: 0px !important;
        padding: 0px !important;

        li {
          margin-right: 0px;
          padding-right: 0px;

          span.title {
            min-width: 80px;
            font-weight: bold;
            display: inline-block;
          }
        }
      }
    }
  }
</style>